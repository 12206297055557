import { Checkbox, FormControlLabel } from '@mui/material';
import { ChangeEvent, ReactElement } from 'react';
import classNames from 'classnames';

import styles from './CheckboxItem.module.scss';
import Svg from '../Svg';

type CheckboxItemProps = {
  size?: number,
  value: boolean,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  label?: string | ReactElement,
  name?: string,
  labelClassName?: string,
  indeterminate?: boolean,
};
const CheckboxItem = ({
  value, onChange, label, size = 18, name, labelClassName, indeterminate,

}: CheckboxItemProps) => (
  <FormControlLabel
    className={classNames(styles.checkbox, labelClassName)}
    sx={{
      gap: '8px',
      margin: '0',
    }}
    control={(
      <Checkbox
        checked={value}
        name={name}
        onChange={onChange}
        indeterminate={indeterminate}
        sx={{
          color: 'inherit',
          p: 0,
          '&.Mui-checked': {
            color: 'inherit',
          },
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
        }}
        icon={(
          <div
            className={styles.icon}
            style={{
              width: size,
              height: size,
            }}
          >
            <Svg name='checkboxEmpty' />
          </div>
        )}
        indeterminateIcon={(
          <div
            className={styles.icon}
            style={{
              width: size,
              height: size,
            }}
          >
            <Svg name='checkboxIndeterminate' />
          </div>
        )}
        checkedIcon={(
          <div
            className={styles.icon}
            style={{
              width: size,
              height: size,
            }}
          >
            <Svg name='checkboxFilled' />
          </div>
        )}
      />
    )}
    label={label}
  />
);

export default CheckboxItem;
