import React from 'react';

type SvgConditionalProps = | {
  name: string,
  path?: never,
} | {
  name?: never,
  path: string,
};

type SvgProps = SvgConditionalProps & {
  className?: string,
};

/* SVG always should be placed in public/media folder and it's name should be same as id */
const Svg = ({ name, className, path }: SvgProps) => (
  <svg className={className}>
    <use
      xlinkHref={path || `/media/icons/${name}.svg#${name}SVG`}
      href={path || `/media/icons/${name}.svg#${name}SVG`}
    />
  </svg>
);

export default Svg;
