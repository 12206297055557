import Link from 'next/link';
import React, { ReactElement } from 'react';
import classnames from 'classnames';
import Svg from '@/src/components/UIKit/Svg.tsx';
import styles from './Button.module.scss';

export enum ButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  OUTLINED = 'outlined',
  ICON = 'icon',
  CUSTOM = 'custom',
}

export enum ButtonIconPosition {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum ButtonSize {
  SMALL = 'small',
  DEFAULT = 'default',
}

type ConditionalProps = | {
  children?: React.ReactNode,
  icon: ReactElement,
  ariaLabel: string,
} | {
  children: React.ReactNode,
  icon?: ReactElement,
  ariaLabel?: string,
};

type CommonProps = {
  className?: string,
  variant?: ButtonVariants,
  iconPosition?: ButtonIconPosition,
  iconSize?: number,
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,
  disabled?: boolean,
  type?: 'button' | 'submit' | 'reset',
  loading?: boolean,
  id?: string,
  size?: ButtonSize,
  onlyStyles?: boolean,
  fullWidth?: boolean,
};

type LinkProps = {
  link?: string,
  target?: string,
};

type ButtonProps = CommonProps & ConditionalProps & LinkProps;

const Button = ({
  className,
  children,
  variant = ButtonVariants.PRIMARY,
  icon,
  ariaLabel,
  iconPosition = ButtonIconPosition.LEFT,
  iconSize,
  onClick,
  link = undefined,
  disabled = false,
  type = 'button',
  loading = false,
  target,
  id,
  size = ButtonSize.DEFAULT,
  onlyStyles = false,
  fullWidth,
}: ButtonProps) => {
  const classes = {
    [styles.primary]: variant === ButtonVariants.PRIMARY,
    [styles.secondary]: variant === ButtonVariants.SECONDARY,
    [styles.danger]: variant === ButtonVariants.DANGER,
    [styles.outlined]: variant === ButtonVariants.OUTLINED,
    [styles.outlined_icon]: variant === ButtonVariants.OUTLINED && !children,
    [styles.icon]: variant === ButtonVariants.ICON,
    [styles.small]: size === ButtonSize.SMALL,
    [styles.withIcon]: icon || loading,
    [styles.withIcon_left]: iconPosition === ButtonIconPosition.LEFT,
    [styles.withIcon_center]: !children || iconPosition === ButtonIconPosition.CENTER,
    [styles.disabled]: disabled,
    [styles.fullWidth]: fullWidth,
  };

  const defaultIconSize = size === ButtonSize.SMALL ? 12 : 18;

  return (link ? (
    <Link
      href={link}
      className={classnames(className, styles.button, classes)}
      target={target}
      id={id}
    >
      {children}
      {(icon || loading) && (
        <div
          className={styles.icon}
          style={{
            width: iconSize || defaultIconSize,
            height: iconSize || defaultIconSize,
          }}
        >
          {loading ? <Svg name='spinner' /> : icon}
        </div>
      )}
    </Link>
  ) : onlyStyles ? (
    <div
      className={classnames(className, styles.button, classes, {
        [styles.disabled]: disabled,
      })}
    >
      {children}
      {(icon || loading) && (
        <div
          className={styles.icon}
          style={{
            width: iconSize || defaultIconSize,
            height: iconSize || defaultIconSize,
          }}
        >
          {loading ? <Svg name='spinner' /> : icon}
        </div>
      )}
    </div>
  ) : (
    <button // eslint-disable-next-line react/button-has-type
      type={type}
      className={classnames(className, styles.button, classes)}
      disabled={disabled}
      onClick={onClick}
      id={id}
      aria-label={ariaLabel}
    >
      {children}
      {(icon || loading) && (
      <div
        className={styles.icon}
        style={{
          width: iconSize || defaultIconSize,
          height: iconSize || defaultIconSize,
        }}
      >
        {loading ? <Svg name='spinner' /> : icon}
      </div>
      )}
    </button>
  ));
};

export default Button;
